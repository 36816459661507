import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import ArrowButton from './arrowButton';
import * as styles from '../assets/css/post.module.css';

const query = graphql`
  query getLatestPost {
    allWpPost (
      limit: 1,
      filter: {categories: {nodes: {elemMatch: {name: {in: ["BandLab", "BandLab Technologies", "Airbit", "Cakewalk", "ReverbNation"]}}}}},
      sort: { date: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          dateGmt(formatString: "DD MMMM YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
          excerpt
        }
      }
    }
  }
`;

const SinglePost = () => {
  const data = useStaticQuery(query);

  const latestPost = data.allWpPost.edges[0].node;

  const { title, featuredImage, dateGmt, slug, excerpt, categories } = latestPost;

  const image = featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData;

  const altText = title;

  return (
    <>
      <div className="pt-[7.292vw] pb-[7.292vw]">
        <div className="gap-x-3 gap-y-12 flex flex-wrap">
          {image ? (
            <div className="flex gap-[6vw] md:flex-col md:mb-16 md:items-center">
              <Link
                to={`/news/${slug}`}
                className="relative image-gradient z-[1] md:w-full mobile:max-w-none flex-1"
              >
                <GatsbyImage
                  image={getImage(image)}
                  alt={altText}
                  className={`${styles.blogImgHolder}`}
                />
                <div className="flex gap-x-3 gap-y-1 absolute top-6 left-6 z-10 flex-wrap pr-12">
                  {categories.nodes.length > 0 && categories.nodes.map((item, index) => {
                    const validNames = new Set(['BandLab', 'Airbit', 'BandLab Technologies', 'Cakewalk', 'ReverbNation']);
                    if (validNames.has(item.name)) {
                      return <h6 key={index} className="borderedTitle !mb-0">{item.name}</h6>;
                    }
                    return null;
                  })}
                </div>
              </Link>
              <div className='grow-[1.2] shrink-[1] basis-0'>
                <h6 className="mb-7">{dateGmt}</h6>
                <Link to={`/news/${slug}`}>
                  <h3 className='text-[2.083vw] md:text-[28px] text-white font-BLT font-medium tracking-[-0.156vw] md:tracking-[-1px] mb-[1.75rem]'>{title}</h3>
                </Link>
                <div dangerouslySetInnerHTML={{ __html: excerpt }} />
                <ArrowButton linkTo={`/news/${slug}`} text="Read More" />
              </div>
            </div>
          ) : (
            <div className='flex gap-[6vw] md:flex-col md:mb-16 md:items-center'>
              <Link
                to={`/news/${slug}`}
                className='relative image-gradient z-[1]  md:w-full mobile:max-w-none flex-1'
              >
                <StaticImage
                  src="../images/blt-post-placeholder.jpg"
                  alt="post-placeholder"
                  placeholder="dominantColor"
                  layout="fullWidth"
                  className={`${styles.blogImgHolder} mb-4`}
                />
                <div className="flex gap-x-3 gap-y-1 absolute top-6 left-6 z-10 flex-wrap pr-12">
                  {categories.nodes.map((item, index) => (
                    <h6 key={index} className="borderedTitle !mb-0">
                      {item.name}
                    </h6>
                  ))}
                </div>
              </Link>
              <div className='grow-[1.2] shrink-[1] basis-0'>
                <h6 className="mb-7">{dateGmt}</h6>
                <Link to={`/news/${slug}`}>
                  <h3 className='text-[2.083vw] md:text-[28px] text-white font-BLT font-medium tracking-[-0.156vw] md:tracking-[-1px] mb-[1.75rem]'>{title}</h3>
                </Link>
                <p dangerouslySetInnerHTML={{ __html: excerpt }} />
                <ArrowButton linkTo={`/news/${slug}`} text="Discover More" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SinglePost;
