import React from 'react';
import { Link , graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import * as styles from '../assets/css/post.module.css';

const query = graphql`
  query getRecentPost {
    allWpPost(sort: { dateGmt: DESC }, limit: 3, filter: {categories: {nodes: {elemMatch: {name: {in: ["BandLab", "BandLab Technologies", "Airbit", "Cakewalk", "ReverbNation"]}}}}}) {
      edges {
        node {
          id
          title
          slug
          dateGmt(formatString: "DD MMMM YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;

const RecentPostList = () => {
  const data = useStaticQuery(query);
  const firstThreePosts = data.allWpPost.edges;
  return (
    <>
      <div className={`${styles.blogWrapper} flex gap-y-16 gap-x-[0.8rem] md:flex-wrap`}>
        {firstThreePosts.map((item) => {
          const { id, title, featuredImage, dateGmt, slug, categories } =
            item.node;

          if (
            featuredImage &&
            featuredImage.node &&
            featuredImage.node.localFile.childImageSharp
          ) {
            const image = getImage(
              featuredImage.node.localFile.childImageSharp.gatsbyImageData,
            );
            const altText = title;

            return (
              <div className={`${styles.individualBlog} group`} key={id}>
                <Link to={`/news/${slug}`} className={`${styles.hasArrow}`}>
                  <GatsbyImage
                    image={image}
                    alt={altText}
                    className={`${styles.blogImgHolder} mb-7`}
                  />
                  <h6 className="text-black mb-3">{dateGmt}</h6>
                  <h4 className='text-black [font-size:clamp(20px,1.458vw,28px)] font-BLT font-medium tracking-[-1px] leading-[1.3]'>
                    {title}
                  </h4>
                  <div className="flex gap-x-3 gap-y-1 absolute top-6 left-6 z-10 flex-wrap pr-12 sm:!hidden opacity-0 group-hover:opacity-100">
                    {categories.nodes.length > 0
                      ? categories.nodes.map((item, index) => (
                        <h6 key={index} className="borderedTitle !mb-0">
                          {item.name}
                        </h6>
                      ))
                      : ''}
                  </div>
                </Link>
              </div>
            );
          }

          // Return null if the image data is not available
          return (
            <div className={`${styles.individualBlog} group`} key={id}>
              <Link to={`/news/${slug}`} className={`${styles.hasArrow}`}>
                <StaticImage
                  src="../images/blt-post-placeholder.jpg"
                  alt="post-placeholder"
                  placeholder="dominantColor"
                  layout="fullWidth"
                  className={`${styles.blogImgHolder} mb-7`}
                />
                <h6 className="text-black mb-3">{dateGmt}</h6>
                <h4 className='text-black [font-size:clamp(20px,1.458vw,28px)] font-BLT font-medium tracking-[-1px] leading-[1.3]'>
                  {title}
                </h4>
                <div className="flex gap-x-3 gap-y-1 absolute top-6 left-6 z-10 flex-wrap pr-12 sm:!hidden opacity-0 group-hover:opacity-100">
                  {categories.nodes.length > 0
                    ? categories.nodes.map((item, index) => (
                      <h6 key={index} className="borderedTitle !mb-0">
                        {item.name}
                      </h6>
                    ))
                    : ''}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RecentPostList;
