module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"blt-web","short_name":"bandlab","start_url":"/","background_color":"#663399","display":"browser","icon":"src/images/blt-favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4eb6f4deb67f31e75326a132ed334a46"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.caldecottmusic.com/graphql","excludeFieldNames":["desiredSlug","dateGmt","enclosure","lastEditedBy","previewBlocks","previewBlockJSON","toPing","fullHead","focuskw","breadcrumbs","cornerstone","commentCount","commentStatus","comments"],"schema":{"perPage":50,"requestConcurrency":4,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"type":{"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
