import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import { Link } from 'gatsby';

import ArrowButton from './arrowButton';
import BandlabLogo from '../images/bandlab-brand-bg-logo.png';
import CakewalkLogo from '../images/cakewalk-brand-bg-logo.png';
import ReverbnationLogo from '../images/reverbnation-brand-bg-logo.png';
import AirbitLogo from '../images/airbit-brand-bg-logo.png';

const initialBltBrands = [
  {
    name: 'BandLab',
    slug: 'bandlab',
    logo: BandlabLogo,
    what: 'SOCIAL MUSIC PLATFORM',
    description: 'The mobile-first cross-platform DAW and social network that unites the entire creator journey into one place.',
    link: '/brands/bandlab/',
  },
  {
    name: 'Cakewalk',
    slug: 'cakewalk',
    logo: CakewalkLogo,
    what: 'ADVANCED MUSIC TOOLS',
    description: 'Suite of native desktop tools that empower creators with advanced workflows to adapt to their unique needs.',
    link: '/brands/cakewalk/',
  },
  {
    name: 'ReverbNation',
    slug: 'reverbnation',
    logo: ReverbnationLogo,
    what: 'POWERFUL ARTIST SERVICES',
    description: 'Dedicated tools and services for musicians to make industry connections and grow their careers.',
    link: '/brands/reverbnation/',
  },
  {
    name: 'Airbit',
    slug: 'airbit',
    logo: AirbitLogo,
    what: 'GLOBAL BEAT MARKETPLACE',
    description: 'A global platform for monetizing instrumental beats, helping producers build sustainable communities and careers.',
    link: '/brands/airbit/',
  },
];

const BrandsListing = ({ currentBrand }) => {
  const brandsWrapperRef = useRef();

  const [brandPlaceholder, setBrandPlaceholder] = useState(false);

  const [bltBrands, setBltBrands] = useState(initialBltBrands);

  useEffect(() => {
    if (currentBrand === 'bandlab') {
      setBrandPlaceholder(true);
    } else if (currentBrand === 'cakewalk') {
      setBrandPlaceholder(true);
    } else if (currentBrand === 'reverbnation') {
      setBrandPlaceholder(true);
    } else if (currentBrand === 'airbit') {
      setBrandPlaceholder(true);
    }
  }, [currentBrand, bltBrands]);

  useEffect(() => {
    function brandOffset() {
      const brandsWrapper = brandsWrapperRef.current;
      if (!brandsWrapper || !brandsWrapper.children) {
        return;
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < brandsWrapper.children.length; i++) {
        if (brandsWrapper?.children[i].children.length > 1) {
          const description = brandsWrapper.children[i].children[1];
          const descHeight = description.clientHeight - 20;
          description.children[0].style.transform = `translateY(${descHeight}px)`;
        }
      }
    }

    // Initial call to brandOffset
    brandOffset();

    // Add event listener to handle resize
    function handleResize() {
      brandOffset();
    }
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const heights = [];

    function brandOffset() {
      const brandsWrapper = brandsWrapperRef.current;
      if (!brandsWrapper || !brandsWrapper.children) {
        return;
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < brandsWrapper.children.length; i++) {
        if (brandsWrapper?.children[i].children.length > 1) {
          const description = brandsWrapper.children[i].children[1];
          const descHeight = description.clientHeight - 20;
          heights[i] = descHeight;
          description.children[0].style.transform = `translateY(${descHeight}px)`;
        }
      }
    }

    // Initial call to brandOffset
    brandOffset();

    // Add event listener to handle resize
    function handleResize() {
      brandOffset();
    }
    window.addEventListener('resize', handleResize);

    if (window.innerWidth < 767) {
      const brands = gsap.utils.toArray('.individualBrand');
      brands.forEach((item, index) => {
        gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'top center',
            end: 'bottom center',
            duration: 1,
            onEnter: () => {
              gsap.to(item.childNodes[0], { opacity: 1 });
              gsap.to(item.childNodes[1].childNodes[0], { y: 0 }, '<');
              gsap.to(item.childNodes[1].childNodes[1], { opacity: 1 }, '<');
              gsap.to(item.childNodes[2], { opacity: 1 }, '<');
              gsap.to(item, { backgroundColor: 'var(--red)' }, '<');
            },
            onLeave: () => {
              gsap.to(item.childNodes[0], { opacity: 0.4 });
              gsap.to(item.childNodes[1].childNodes[0], { y: `${heights[index]}px`, ease: 'none' }, '<');
              gsap.to(item.childNodes[1].childNodes[1], { opacity: 0 }, '<');
              gsap.to(item.childNodes[2], { opacity: 0 }, '<');
              gsap.to(item, { backgroundColor: '#242424' }, '<');
            },
            onEnterBack: () => {
              gsap.to(item.childNodes[0], { opacity: 1 });
              gsap.to(item.childNodes[1].childNodes[0], { y: 0 }, '<');
              gsap.to(item.childNodes[1].childNodes[1], { opacity: 1 }, '<');
              gsap.to(item.childNodes[2], { opacity: 1 }, '<');
              gsap.to(item, { backgroundColor: 'var(--red)' }, '<');
            },
            onLeaveBack: () => {
              gsap.to(item.childNodes[0], { opacity: 0.4 });
              gsap.to(item.childNodes[1].childNodes[0], { y: `${heights[index]}px`, ease: 'none' }, '<');
              gsap.to(item.childNodes[1].childNodes[1], { opacity: 0 }, '<');
              gsap.to(item.childNodes[2], { opacity: 0 }, '<');
              gsap.to(item, { backgroundColor: '#242424' }, '<');
            },
          },
        });

        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      });
    }
  }, []);

  return (
    <section className="bg-black brands-section z-10">
      <div className="flex flex-col gap-y-2 pb-4">
        <div className="gap-[0.8rem] flex mlg:flex-wrap" ref={brandsWrapperRef}>
          {brandPlaceholder && (
            <div className="pointer-events-none bg-transparent w-1/4 px-[1.563vw] pt-[1.563vw] pb-[2.604vw] ipad:w-full ipad:flex ipad:aspect-[unset] ipad:py-0 ipad:px-6 ipad:justify-center ipad:mb-3">
              <h6>MORE TECHNOLOGIES</h6>
            </div>
          )}

          {bltBrands.filter(brand => currentBrand !== brand.slug).map((brand, index) => (
            <div
              className="group/brand individualBrand fade-in hover:bg-red duration-300 bg-[#242424] flex flex-col aspect-[0.66] w-1/4 pt-[1.563vw] px-[1.563vw] pb-[2.604vw] md:pointer-events-none mlg:!w-[calc(50%-0.4rem)] mlg:aspect-[1.25] mlg:p-[3.3rem] ipad:flex-[unset] ipad:aspect-[0.85] ipad:!px-[5%] ipad:!py-[8%] md:aspect-[2] md:!w-full mobile:!aspect-square"
              key={brand.slug}>
              <Link to={brand.link}>
                <img
                  src={brand.logo}
                  alt={`${brand.name} Logo`}
                  className="min-w-[280px] md:max-w-[50vw] w-[16.583vw] opacity-40 group-hover/brand:opacity-100 !duration-300 !delay-0 group/triggered"
                />
              </Link>
              <div className="flex-1 mt-[25%] md:mt-[10%] mobile:mt-[15%]">
                <h6 className="borderedTitle group-hover/brand:!translate-y-0 mn:duration-300">
                  {brand.what}
                </h6>
                <h5 className="text-white opacity-0 group-hover/brand:opacity-100 mn:duration-300">
                  {brand.description}
                </h5>
              </div>
              <div className="opacity-0 group-hover/brand:opacity-100 mn:duration-300">
                <ArrowButton linkTo={brand.link} text="Learn More" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BrandsListing;
