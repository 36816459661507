import React, { useLayoutEffect, useRef } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ArrowButton from './arrowButton';
import ExternalLinkArrowButton from './externalLinkArrowButton';

const Footer = () => {
  const year = new Date().getFullYear();
  const animationRef = useRef(null);

  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const footerReveals = document.querySelectorAll('.footer-reveal');

    function footerTextReveal() {
      footerReveals.forEach((item) => {
        const spans = item.querySelectorAll('span');

        gsap.set(spans, {
          yPercent: 115,
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: item,
            start: 'top 90%',
            end: 'top 90%',
            scrub: 1,
            markers: false,
          },
        });

        tl.to(spans, {
          yPercent: 0,
          stagger: 0.5,
          ease: 'linear',
        });

        animationRef.current = tl;
      });
    }

    footerTextReveal();
  });
  return (
    <footer
      className="
      bg-black md:pt-[60px] px-8 pb-24 flex flex-col relative min-h-screen w-full before:bg-gradient-to-b before:from-black before:w-full before:h-[40%] before:top-0 before:left-0 before:absolute before:z-0 before:pointer-events-none
      after:absolute after:w-full after:h-full after:top-0 after:left-0 after:-z-[1] after:pointer-events-none after:bg-no-repeat after:bg-center-top
      md:z-[998]
      "
      id="Footer"
    >
      <div id="lottie-footer-animation" />
      <div className="footerCta flex flex-col justify-center items-center flex-1 gap-y-4 z-[1] md:items-start md:mb-[12.526vw]">
        <h2 className="line-split mask-anim !max-w-[50vw] justify-center text-[6.25vw] md:text-[50px] text-center mx-auto leading-[1.15] md:text-left md:!max-w-[unset] md:w-full md:justify-start">
          Stay connected with us
        </h2>
        <ArrowButton linkTo="/contact/" text="Contact Us" />
        <ExternalLinkArrowButton
          linkTo="https://www.linkedin.com/company/bandlab/"
          text="Find us on LinkedIn"
          target="blank"
        />
      </div>
      <div className="footerBottom flex">
        <div className="footerCol footerLogo lg:!hidden flex flex-col basis-[30%] justify-between gap-y-4 gap-x-8">
          <StaticImage
            src="../images/bandlabtechnologies-logo.svg"
            alt="right-pic"
            placeholder="dominantColor"
            layout="constrained"
            className="footerImage "
            width={1024}
            height={1024}
          />
          <h6>
            We shape the future of music by building tools for a new generation
            of music creators and fans.
          </h6>
        </div>
        <div className="footerCol footerSocial lg:!hidden flex flex-col basis-[30%] justify-between gap-y-4 gap-x-8">
          <div className="inner">{/* Placeholder */}</div>
        </div>
        <div className="footerCol subscribeForm lg:!hidden flex flex-col basis-[30%] justify-between gap-y-4 gap-x-8">
          {/* Placeholder */}
        </div>
        <div className="footerCol footerMenu md:!basis-full md:flex-wrap flex flex-col basis-[10%] justify-between gap-y-4 gap-x-8">
          <div className="inner md:!w-2/5">
            <Link to="/brands/bandlab/" className="whiteButton">
              BANDLAB
            </Link>
            <Link to="/brands/cakewalk/" className="whiteButton">
              CAKEWALK
            </Link>
            <Link to="/brands/reverbnation/" className="whiteButton">
              REVERBNATION
            </Link>
            <Link to="/brands/airbit/" className="whiteButton">
              AIRBIT
            </Link>
          </div>
          <div className="inner bottomMenu md:!w-2/5">
            <h6 className="md:hidden">
              © {year || 2024} BANDLAB TECHNOLOGIES <br />A PART OF{' '}
              <a
                href="https://caldecottmusic.com/"
                target="_blank"
                className="!p-0 !bg-transparent !text-white"
              >
                CALDECOTT MUSIC GROUP
              </a>
            </h6>
            <Link to="/">Home</Link>
            <Link to="/news/">News</Link>
            <Link to="/careers/">Careers</Link>
            <Link to="/team/">Team</Link>
            <Link to="/contact/">Contact</Link>
          </div>
          {/* {new Date().getFullYear()} */}
          <div className="mobile-footer-copy mn:hidden mt-5 pl-3 order-4">
            <h6>
              © {year || 2024} BANDLAB TECHNOLOGIES <br />A PART OF{' '}
              <a href="https://caldecottmusic.com/" target="_blank">
                CALDECOTT MUSIC GROUP
              </a>
            </h6>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
