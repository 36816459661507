import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as styles from '../assets/css/visionslider.module.css';
import weWelcomeAllImg from '../images/we-welcome-all-img.jpg';
import weMakeMoves from '../images/vision-img-1.jpg';
import weStayEngageImg from '../images/vision-img-3.jpg';

const VisionSlider = () => {
  const sliderBgRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const sections = gsap.utils.toArray('.vision-slides');
    const sectionsLength = sections.length - 1;

    sections.forEach((section, index) => {
      const slidetl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          // pin: section,
          // pinSpacing: false,
          start: 'top 50%',
          toggleActions:
            index === sectionsLength
              ? 'play reverse play reverse'
              : index === 0
                ? 'play reverse play reverse'
                : 'play reverse play reverse',
        },
      });

      slidetl.fromTo(
        section.children[0].children[0],
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.25,
        },
      );
      slidetl.fromTo(
        section.children[0].children[1],
        { y: 30, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.2,
        },
        '<');
      slidetl.fromTo(
        section.children[1],
        {
          clipPath: 'polygon(0% 0, 0 0, 0 100%, 0% 100%)',
        },
        {
          clipPath: 'polygon(100% 0, 0 0, 0 100%, 100% 100%)',
          ease: 'cubic-bezier(.5,.44,.02,.88)',
          opacity: 1,
        },
        '<',
      );
      slidetl.fromTo(
        section.children[2].children,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          stagger: 0.05,
          ease: 'power2.inOut',
        },
        '<',
      );

      const bgtl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: 'top 50%',
          end: '-10% 50%',
          duration: 5,
          scrub: true,
          toggleActions: 'play reverse play reverse',
        },
      });
      if (index === 0) {
        bgtl.to(sliderBgRef.current, { background: 'var(--white)' });
      } else if (index === 1) {
        bgtl.to(sliderBgRef.current, { background: 'var(--red)' });
      } else if (index === 2) {
        bgtl.to(sliderBgRef.current, { background: 'var(--black)' });
      }
    });
  }, []);

  return (
    <section ref={sliderBgRef} className={`${styles.slidesSection}`}>
      <div className={`bright vision-slides ${styles.slides} `}>
        <div className={styles.leftCol}>
          <h6>MUSIC FOR EVERYONE</h6>
          <p>We believe everyone should be able to enjoy and create music.</p>
        </div>
        <div className={`${styles.middleCol}`}>
          {/* <StaticImage
            src="../images/we-welcome-all-img.jpg"
            alt="vission-image-2"
            placeholder="dominantColor"
            layout="constrained"
            width={540}
            height={540}
          /> */}
          <img src={weWelcomeAllImg} alt="" />
        </div>
        <div className={`${styles.rightCol}`}>
          <span>We</span>
          <span>Welcome</span>
          <span>All</span>
        </div>
      </div>

      <div className={`vision-slides ${styles.slides}`}>
        <div className={styles.leftCol}>
          <h6>INNOVATION FIRST</h6>
          {/* <h6 className={`md:hidden  md:h-0`}>INNOVATION FIRST</h6> */}
          <p>
            Paving the way for new opportunities to create, earn, and grow.
          </p>
        </div>
        <div className={styles.middleCol}>
          {/* <StaticImage
            src="../images/vision-img-1.jpg"
            alt="vission-image-1"
            placeholder="dominantColor"
            layout="constrained"
            width={540}
            height={540}
          /> */}
          <img src={weMakeMoves} alt="" />

          {/* <img src={visionOne} alt="" loading="lazy" /> */}
        </div>
        {/* <h6 className={`mn:hidden`}>INNOVATION FIRST</h6> */}
        <div className={styles.rightCol}>
          <span>We</span>
          <span>Make</span>
          <span>Moves</span>
        </div>
      </div>

      <div className={`vision-slides ${styles.slides}`}>
        <div className={styles.leftCol}>
          <h6>A COLLABORATIVE EXPERIENCE</h6>
          <p>
            Actively involved with creators and industry allies to foster a strong sense of community.
          </p>
        </div>
        <div className={styles.middleCol}>
          {/* <StaticImage
            src="../images/vision-img-3.jpg"
            alt="vission-image-3"
            placeholder="dominantColor"
            layout="constrained"
            width={540}
            height={540}
          /> */}
          <img src={weStayEngageImg} alt="" />
        </div>
        <div className={styles.rightCol}>
          <span>We</span>
          <span>Stay</span>
          <span>Engaged</span>
        </div>
      </div>
    </section>
  );
};

export default VisionSlider;
