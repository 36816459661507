import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import * as styles from '../assets/css/post.module.css';
import Seo from '../components/seo';
import RecentPostList from '../components/recentPostList';

const BlogPost = ({ data }) => {
  const { title, content, dateGmt, categories } = data.allWpPost.edges[0].node;

  useEffect(() => {
    const para = document.querySelectorAll('p');
    const listitems = document.querySelectorAll('li');
    const headings3 = document.querySelectorAll('h3');
    const headings4 = document.querySelectorAll('h4');

    para.forEach((item) => {
      item.classList.add('has-fade-in');
    });
    listitems.forEach((item) => {
      item.classList.add('has-fade-in');
    });
    headings3.forEach((item) => {
      item.classList.add('has-fade-in');
    });
    headings4.forEach((item) => {
      item.classList.add('has-fade-in');
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
            observer.unobserve(entry.target); // Unobserve the element to avoid applying the animation again if it scrolls out of view and back in
          }
        });
      },
      {
        threshold: 0.3, // Adjust this threshold to define when the animation should trigger (0.0 to 1.0)
      },
    );

    para.forEach((paragraph) => {
      observer.observe(paragraph);
    });
    listitems.forEach((list) => {
      observer.observe(list);
    });
    headings3.forEach((heading) => {
      observer.observe(heading);
    });
    headings4.forEach((heading) => {
      observer.observe(heading);
    });

    return () => observer.disconnect(); // Cleanup function to disconnect the observer when the component unmounts
  }, []);

  return (
    <Layout>
      <section className={`${styles.postTopSection} bg-black`}>
        <h1 className="line-split mask-anim">
          {title}
        </h1>
        <div className={`${styles.postDateCat}`}>
          <h6 className="text-white">{dateGmt}</h6>
          <div className="flex gap-x-3 gap-y-1 flex-wrap">
            {categories.nodes.length > 0 && categories.nodes.map((item, index) => {
              const validNames = new Set(['BandLab', 'Airbit', 'BandLab Technologies', 'Cakewalk', 'ReverbNation']);
              if (validNames.has(item.name)) {
                return <h6 key={index} className="borderedTitle !mb-0">{item.name}</h6>;
              }
              return null;
            })}
          </div>
        </div>
      </section>
      <section className={`${styles.postContentSection} bg-black`}>
        <div className={`${styles.postContentHolder} max-w-[800px]`}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </section>
      <section className={`${styles.relatedPost}`}>
        <h3>More articles</h3>
        <RecentPostList />
      </section>
    </Layout>
  );
};

export const query = graphql`
  query SinglePost($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          slug
          dateGmt(formatString: "DD MMMM YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
          content
          seo {
            opengraphDescription
            opengraphSiteName
            opengraphTitle
            opengraphType
          }
        }
      }
    }
  }
`;

export const Head = (props) => (
  <Seo
    title={props.data.allWpPost.edges[0].node.seo.opengraphTitle}
    description={props.data.allWpPost.edges[0].node.seo.opengraphDescription}
  />
);

export default BlogPost;
