import React, { useState, useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const Counter = ({ targetValue, duration }) => {
  const [count, setCount] = useState(0);
  const countRef = useRef(null);

  useEffect(() => {
    const startCounterAnimation = () => {
      const counterElement = countRef.current;
      gsap.to(counterElement, {
        duration: duration || 1, // Default duration is 1 second if not provided
        innerText: targetValue,
        roundProps: 'innerText', // Round the number for better visual effect
        onUpdate: () => {
          setCount(Math.round(counterElement.innerText));
        },
      });
    };

    const counterElement = countRef.current;

    // Create an Intersection Observer to watch for visibility changes
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // When the element enters the viewport, start the counter animation
            startCounterAnimation();
            observer.unobserve(counterElement); // Stop observing once animation starts
          }
        });
      },
      { threshold: 0.5 }, // The threshold value controls how much of the element needs to be visible to trigger the animation
    );

    // Start observing the counter element
    observer.observe(counterElement);

    // Clean up the animation on component unmount
    return () => {
      gsap.killTweensOf(counterElement);
    };
  }, [targetValue, duration]);

  return <span ref={countRef}>{count}</span>;
};

export default Counter;
