import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const DummyArrowButton = ({ text }) => (
  <div
    className='group flex items-center gap-x-4 uppercase rounded-[100px] py-[1px] pr-[10px] pl-[3px] hover:bg-white hover:text-black1 !duration-300 !delay-0'
  >
    <div className="border-x border-y border-white rounded-full !w-4 !h-4 grid place-items-center relative group-hover:brightness-0 !duration-300 !delay-0">
      <StaticImage
        src="../images/small-arrow.svg"
        alt="small-arrow"
        width={8}
        height={8}
        layout="fixed"
        className="absolute top-0 bottom-0 left-0 right-0 m-auto aspect-square object-contain !w-2 !h-2"
      />
    </div>
    {text}
  </div>
);

export default DummyArrowButton;
