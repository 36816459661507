import React, { useEffect, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../assets/css/global.css';
import '../assets/css/layout.css';
import Header from './header';
import Footer from './footer';
import ScrollToTop from './scrollToTop';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  // Text Typing Animation
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const texts = gsap.utils.toArray('.text-clip');

    texts.forEach(text => {
      gsap.set(text, { clipPath: 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)' });

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: text,
          start: 'top 95%',
          end: 'top 95%',
          scrub: 0.6,
          toggleActions: 'play reverse play reverse',
          ease: 'linear',
        },
      });

      tl.to(text, {
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
      });
    });

    // Return the cleanup function
    return () => {
      gsap.registerPlugin(ScrollTrigger);

      texts.forEach(text => {
        const scrollTriggerInstance = ScrollTrigger.getById(text);
        if (scrollTriggerInstance) {
          scrollTriggerInstance.kill(true); // true means kill animations as well
        }
      });
    };
  }, []);

  // Big Heading Reveal animation
  const animationRef = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const headingReveals = document.querySelectorAll('.heading-reveal');
    function textReveal() {
      // Loop through each "heading-reveal" element and apply the animation
      headingReveals.forEach(headingReveal => {
        const spans = headingReveal.querySelectorAll('span');

        gsap.set(spans, {
          yPercent: 115,
        });

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: headingReveal,
            start: 'top 90%',
            end: 'top 90%',
            scrub: 1,
          },
        });

        tl.to(spans, {
          yPercent: 0,
          stagger: 0.25,
          ease: 'linear',
        });

        animationRef.current = tl;
      });
    }

    textReveal();
  }, []);

  useEffect(() => {
    const fadeIns = document.querySelectorAll('.fade-in');

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
            observer.unobserve(entry.target); // Unobserve the element to avoid applying the animation again if it scrolls out of view and back in
          }
        });
      },
      {
        threshold: 0.3, // Adjust this threshold to define when the animation should trigger (0.0 to 1.0)
      },
    );

    fadeIns.forEach(item => {
      observer.observe(item);
    });

    return () => observer.disconnect(); // Cleanup function to disconnect the observer when the component unmounts
  }, []);

  // Initial Heading Fade In Animation
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const initialHeadings = document.querySelectorAll('.initial-heading-reveal:not(.img-reveal)');
    function runInitialAnimation() {
      // Loop through each "heading-reveal" element and apply the animation
      initialHeadings.forEach(initialHeading => {
        const spans = initialHeading.querySelectorAll('span');

        gsap.set(spans, { yPercent: 115 });

        gsap.to(spans, {
          yPercent: 0,
          stagger: 0.2,
          ease: 'cubic-bezier(.79,1.37,1,.92))',
          duration: 0.8,
        });
      });
    }

    runInitialAnimation();
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const initialHeadings = document.querySelectorAll('.img-reveal');
    function runInitialAnimation() {
      // Loop through each "heading-reveal" element and apply the animation
      initialHeadings.forEach(initialHeading => {
        const spans = initialHeading.querySelectorAll('span');

        gsap.set(spans, { yPercent: 115 });

        gsap.to(spans, {
          yPercent: 0,
          stagger: 0.2,
          ease: 'cubic-bezier(.79,1.37,1,.92))',
          duration: 0.8,
        });
      });
    }

    runInitialAnimation();
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const initialLineFade = document.querySelectorAll('.line-fade-in.initial');
    function runInitialAnimation() {
      // Loop through each "heading-reveal" element and apply the animation
      initialLineFade.forEach(initialHeading => {
        const spans = initialHeading.querySelectorAll('span');

        gsap.set(spans, { yPercent: 115, opacity: 0 });

        gsap.to(spans, {
          yPercent: 0,
          opacity: 1,
          stagger: 0.2,
          // ease: "cubic-bezier(.79,1.37,1,.92))",
          // duration: 0.8,
          marker: true,
        });
      });
    }

    runInitialAnimation();
  }, []);

  // Line Fade In animation
  // const lineFadeAnimationRef = useRef(null);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const headingContainers = document.querySelectorAll('.line-fade-in:not(.initial)');
    headingContainers.forEach((container) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
          stagger: 0.085,
          ease: 'ease-out',
        },
      });

      const innerSpans = Array.from(container.querySelectorAll('.line-fade-in:not(.initial) span'));
      tl.fromTo(innerSpans, {
        opacity: 0,
        yPercent: 100,
      }, {
        opacity: 1,
        yPercent: 0,
        stagger: 0.15,
        duration: 0.35,
      });
    });
  }, []);

  useEffect(() => {

    const elements = document.querySelectorAll('.line-split');
    elements.forEach((element) => {
    // Get the text content of the element
      const text = element.textContent;
      // Split the text into words
      const words = text.split(' ');
      // Clear the content of the element
      element.textContent = '';

      // Loop through each word and create nested <span> elements
      words.forEach((word) => {
        const outerSpan1 = document.createElement('span');
        const outerSpan2 = document.createElement('span');
        const innerSpan = document.createElement('span');
        innerSpan.textContent = word;

        outerSpan2.appendChild(innerSpan);
        outerSpan1.appendChild(outerSpan2);

        // Append the nested spans to the element
        element.appendChild(outerSpan1);

        // Add a space between spans
        element.appendChild(document.createTextNode(' '));
      });
    });

  }, []);

  useEffect(() => {
    const headingContainers = document.querySelectorAll('.mask-anim');
    headingContainers.forEach((container) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      });

      const innerSpans = Array.from(container.querySelectorAll('.mask-anim span span span'));
      tl.from(innerSpans, {
        yPercent: 100,
        // rotation: 13,
        transformOrigin: 'left bottom',
        stagger: 0.085,
        duration: 0.35,
      });
    });
  }, []);

  return (
    <>
      <ScrollToTop />
      <Header siteTitle={data.site.siteMetadata?.title || 'Title'} />
      <main id="Content">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
