import * as React from 'react';
import Seo from '../components/seo';
import ArrowButton from '../components/arrowButton';

const NotFoundPage = () => (
  <>
    <section className="flex items-center justify-center h-screen w-screen flex-col">
      <h1 className='mn:text-[16.667vw] leading-[1] font-CM mb-[3vh]'>404</h1>
      <ArrowButton linkTo="/" text="Back To Homepage" />
    </section>
  </>
);

export const Head = () => <Seo title="404 | Page Not Found" description="BandLab Technologies 404 Page" />;

export default NotFoundPage;
