import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as Select from '@radix-ui/react-select';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../assets/css/career.module.css';
import ExternalLinkArrowButton from './externalLinkArrowButton';
import { SelectContent, SelectItem, SelectTrigger } from './select';

// Separate component to render career item
const CareerItem = ({ item }) => {
  const { location } = item;

  return (
    <div
      key={item.id}
      className={`flex pt-5 pb-5 px-8 ${styles.careerRow} md:flex-col relative group/career before:absolute before:top-0 before:left-0 before:bottom-0 before:w-full before:h-[0%] before:mt-auto before:mb-auto before:bg-red before:duration-300 hover:before:h-full`}
    >
      <div
        className={`basis-[27.604vw] md:basis-0 ${styles.careerPosition} z-10`}
      >
        {item.department?.name && (
          <h6 className="borderedTitle">{item.department.name}</h6>
        )}
      </div>
      <a
        className="flex flex-col gap-y-1 flex-[1.5] z-10"
        href={`${item.url}`}
        target="_blank"
      >
        <div className="flex items-center flex-wrap mb-[0.6rem]">
          <p className="!m-0 font-BLT text-[1.354vw] md:text-[20px] mb-[0.4rem] leading-[1] font-medium tracking-normal">
            {item.title}
          </p>
        </div>
        <div className="flex gap-x-[1.6rem] sm:flex-col items-center sm:items-start sm:gap-y-[0.8rem]">
          <div className="flex items-center">
            <h6 className="relative">{location.name}</h6>
          </div>
          {item.employment_type_text && (
            <h6 className="borderedTitle !m-0">{item.employment_type_text}</h6>
          )}
          <h6>{item.workplace_type}</h6>
        </div>
      </a>
      <div className="flex flex-[0.8] items-center md:justify-end group-hover/career:opacity-100 group-hover/career:translate-x-0 opacity-0 translate-x-5 duration-300">
        <ExternalLinkArrowButton
          linkTo={`${item.url}`}
          text="Apply"
          target="blank"
        />
      </div>
    </div>
  );
};

const categorys = [
  'A&R',
  'AI',
  'Android',
  'Artist Marketing',
  'Audio Core',
  'Backend',
  'Community',
  'Content Partnerships',
  'Design',
  'Finance',
  'Frontend',
  'Full Stack',
  'HR',
  'Marketing',
  'Product',
  'QA',
  'R&D',
  'Sound Content',
  'iOS',
];

const locations = ['United States', 'Philippines', 'Mexico', 'Singapore'];

const workplaceTypes = ['Onsite', 'Hybrid', 'Remote'];

const CareerList = () => {
  const [careerData, setCareerData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState('allroles');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedWorkplaceType, setSelectedWorkplaceType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  // const handleOptionChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.GATSBY_PINPOINT_API_ROUTE);
      setCareerData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(true);
    }
  };

  const getFilteredJobs = () => {
    let result = careerData;
    if (searchTerm) {
      const searchTermLower = searchTerm.toLowerCase();
      result = careerData.filter((item) => {
        const title = item.title.toLowerCase();
        const department = item.department.name.toLowerCase();
        const location = item.location.name.toLowerCase();
        const workplaceType = item.workplace_type.toLowerCase();
        return (
          title.includes(searchTermLower) ||
          location.includes(searchTermLower) ||
          department.includes(searchTermLower) ||
          workplaceType.includes(searchTermLower)
        );
      });
    }

    if (selectedCategory) {
      result = result.filter(
        (item) =>
          item.department.name.toLowerCase() === selectedCategory.toLowerCase()
      );
    }

    if (selectedLocation) {
      result = result.filter(
        (item) =>
          item.location.name.toLowerCase() === selectedLocation.toLowerCase()
      );
    }

    if (selectedWorkplaceType) {
      result = result.filter(
        (item) =>
          item.workplace_type.toLowerCase() ===
          selectedWorkplaceType.toLowerCase()
      );
    }

    return result;
  };
  // const filteredByTypeJobs =
  //   selectedOption === 'allroles'
  //     ? careerData
  //     : careerData.filter((item) => item.employment_type === selectedOption);

  // const filteredJobs = filteredByTypeJobs.filter((item) => {
  //   return true;
  // });

  const groupedJobs = getFilteredJobs().reduce((acc, item) => {
    const deptName = item.department.name;
    if (!acc[deptName]) {
      acc[deptName] = [];
    }
    acc[deptName].push(item);
    return acc;
  }, {});

  return (
    <>
      {loading ? (
        <div className="bg-black h-screen w-full text-center grid items-center">
          <p>Loading Careers . . .</p>
        </div>
      ) : (
        <div className="pt-[7.292vw] pb-[7.292vw]">
          <div className="careerFilterHolder pl-[29.271vw] pr-[21vw] md:pl-8 md:pr-8">
            <div className="relative font-CM mb-8 sm:mb-6 border-b border-grey1 focus-within:border-red gap-1.5 duration-300">
              <div className="absolute pointer-events-none mt-1 sm:mt-1.5">
                <svg
                  className="h-4 w-4 sm:h-3 sm:w-3"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.33301 0.833496C4.19087 0.833496 0.833008 4.19136 0.833008 8.3335C0.833008 12.4756 4.19087 15.8335 8.33301 15.8335C12.4751 15.8335 15.833 12.4756 15.833 8.3335C15.833 4.19136 12.4751 0.833496 8.33301 0.833496ZM2.49967 8.3335C2.49967 5.11184 5.11135 2.50016 8.33301 2.50016C11.5547 2.50016 14.1663 5.11184 14.1663 8.3335C14.1663 11.5552 11.5547 14.1668 8.33301 14.1668C5.11135 14.1668 2.49967 11.5552 2.49967 8.3335Z"
                    fill="white"
                  />
                  <path
                    d="M18.9223 17.7444L15.589 14.411L14.4105 15.5895L17.7437 18.9228L18.9223 17.7444Z"
                    fill="white"
                  />
                </svg>
              </div>

              <input
                type="text"
                placeholder="START TYPING JOB TITLE OR KEYWORDS"
                className="w-full font-medium text-sm sm:text-xs placeholder-grey2 pb-3 sm:pb-2 sm:ps-5 ps-6 bg-black text-white border-white outline-none"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {/* <div className="absolute flex items-center end-0 bottom-2.5 sm:bottom-2 gap-2 pointer-events-none">
                <div className="shrink-0 -mt-0.5 border-x border-y border-white rounded-full w-4 h-4 grid place-items-center relative group-hover:brightness-0 !duration-300 !delay-0">
                  <StaticImage
                    src="../images/small-arrow.svg"
                    alt="small-arrow"
                    width={8}
                    height={8}
                    layout="fixed"
                    className="absolute top-0 bottom-0 left-0 right-0 m-auto aspect-square object-contain"
                  />
                </div>

                <span className="text-white sm:text-xs font-medium">
                  SEARCH
                </span>
              </div> */}
            </div>

            {/* filter dropdown selects */}
            <div className="flex flex-wrap gap-x-8 gap-y-4 mb-4">
              <div className="grid">
                <label
                  htmlFor="category"
                  className={`mb-2 text-white text-[13px] font-CM ${styles.removeBefore}`}
                >
                  CATEGORY:
                </label>

                <Select.Root onValueChange={(val) => setSelectedCategory(val)}>
                  <SelectTrigger placeholder="All" label="category" />
                  <SelectContent>
                    <SelectItem text="All" value={null} />
                    {categorys.map((category) => (
                      <SelectItem
                        text={category}
                        value={category}
                        key={category}
                      />
                    ))}
                  </SelectContent>
                </Select.Root>
              </div>

              <div className="grid">
                <label
                  htmlFor="location"
                  className={`mb-2 text-white text-[13px] font-CM ${styles.removeBefore}`}
                >
                  LOCATION:
                </label>
                <Select.Root onValueChange={(val) => setSelectedLocation(val)}>
                  <SelectTrigger placeholder="All" label="location" />
                  <SelectContent>
                    <SelectItem text="All" value={null} />
                    {locations.map((loc) => (
                      <SelectItem text={loc} value={loc} key={loc} />
                    ))}
                  </SelectContent>
                </Select.Root>
              </div>

              <div className="grid">
                <label
                  htmlFor="workplace"
                  className={`mb-2 text-white text-[13px] font-CM ${styles.removeBefore}`}
                >
                  JOB TYPE:
                </label>

                <Select.Root
                  onValueChange={(val) => setSelectedWorkplaceType(val)}
                >
                  <SelectTrigger placeholder="All" label="workplace" />
                  <SelectContent>
                    <SelectItem text="All" value={null} />
                    {workplaceTypes.map((workplace) => (
                      <SelectItem
                        text={workplace}
                        value={workplace}
                        key={workplace}
                      />
                    ))}
                  </SelectContent>
                </Select.Root>
              </div>
            </div>

            {/* <h6 className="mb-6">Showing :</h6>
            <div>
              <div
                className={`${styles.careerFilters} pb-[3.125vw] text-[0.677vw] md:text-[10px] gap-x-[6.25vw] flex flex-wrap gap-y-[0.6rem]`}
              >
                <label className="sm:mb-[0.6rem] text-[13px] tracking-[2px] font-CM relative text-white flex items-center gap-x-[0.6rem] cursor-pointer pt-[2px] pr-[6px] pb-0 pl-0 rounded-[50px] duration-300 md:mb-[0.6rem] uppercase">
                  <input
                    type="radio"
                    value="allroles"
                    checked={selectedOption === 'allroles'}
                    onChange={handleOptionChange}
                    data-active={selectedOption === 'allroles'}
                    className="checked:before:bg-red before:content-[*]"
                  />
                  All Roles
                </label>
                <label className="sm:mb-[0.6rem] text-[13px] tracking-[2px] font-CM relative text-white flex items-center gap-x-[0.6rem] cursor-pointer pt-[2px] pr-[6px] pb-0 pl-0 rounded-[50px] duration-300 md:mb-[0.6rem] uppercase">
                  <input
                    type="radio"
                    value="full_time"
                    checked={selectedOption === 'full_time'}
                    onChange={handleOptionChange}
                    data-active={selectedOption === 'full_time'}
                    className="checked:before:bg-red"
                  />
                  Full Time
                </label>
                <label className="sm:mb-[0.6rem] text-[13px] tracking-[2px] font-CM relative text-white flex items-center gap-x-[0.6rem] cursor-pointer pt-[2px] pr-[6px] pb-0 pl-0 rounded-[50px] duration-300 md:mb-[0.6rem] uppercase">
                  <input
                    type="radio"
                    value="part_time"
                    checked={selectedOption === 'part_time'}
                    onChange={handleOptionChange}
                    data-active={selectedOption === 'part_time'}
                    className="checked:before:bg-red"
                  />
                  Part Time
                </label>
              </div>
            </div> */}
          </div>

          {Object.keys(groupedJobs).length === 0 && (
            <div className="grid p-20 sm:px-8 place-items-center">
              <p className="font-medium text-lg">No results found</p>

              <button
                onClick={() => {
                  setSearchTerm('');
                  setSelectedCategory('');
                  setSelectedLocation('');
                  setSelectedWorkplaceType('');
                }}
                className="group flex items-center gap-x-4 uppercase rounded-[100px] py-[1px] pr-[10px] pl-[3px] hover:bg-white hover:text-black1 !duration-300 !delay-0 md:pointer-events-auto"
              >
                <div className="shrink-0 border-x border-y border-white rounded-full !w-4 !h-4 grid place-items-center relative group-hover:brightness-0 !duration-300 !delay-0">
                  <StaticImage
                    src="../images/small-arrow.svg"
                    alt="small-arrow"
                    width={8}
                    height={8}
                    layout="fixed"
                    className="absolute top-0 bottom-0 left-0 right-0 m-auto aspect-square object-contain"
                  />
                </div>
                Reset all filters
              </button>
            </div>
          )}
          <div>
            {Object.keys(groupedJobs).map((deptName, index) => (
              <div key={index}>
                <div className={`${styles.careerHolder} mb-[3.125vw] md:mb-6`}>
                  {groupedJobs[deptName].map((item, index2) => (
                    <CareerItem key={index2} item={item} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CareerList;
