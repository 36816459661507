import React from 'react';
import img1 from '../images/billboard-logo.svg';
import img2 from '../images/techcrunch-logo.svg';
import img3 from '../images/musicbusiness-logo.png';
import img4 from '../images/variety-logo.svg';
import img5 from '../images/musicradar-logo.png';
import img6 from '../images/daily-double-logo.png';
import img7 from '../images/musictech-logo.svg';
import img8 from '../images/nme-logo.svg';
import img9 from '../images/cbs-logo.png';

const RollingBrandsList = () => (
  <div className='pt-16 pb-16'>
    <div className='mb-12 text-center w-full'><h6 className='m-auto'>As Seen In</h6></div>

    {/* <div className='marque-list inline-flex'>
      <div className='animate-toLeft inline-flex gap-x-24 pl-24 pr-12 items-center'>
        <StaticImage
          src="../images/billboard-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={160}
          height={32}
        />
        <StaticImage
          src="../images/techcrunch-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={64}
          height={32}
        />
        <StaticImage
          src="../images/musicbusiness-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={144}
          height={32}
        />
        <StaticImage
          src="../images/variety-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={128}
          height={32}
        />
        <StaticImage
          src="../images/musicradar-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={160}
          height={32}
        />
        <StaticImage
          src="../images/daily-double-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={100}
          height={60}
        />
        <StaticImage
          src="../images/musictech-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={176}
          height={32}
        />
        <StaticImage
          src="../images/nme-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={96}
          height={32}
        />
        <StaticImage
          src="../images/cbs-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={320}
          height={32}
        />
      </div>
      <div className='animate-toLeft inline-flex gap-x-24 pl-12 pr-12  items-center'>
        <StaticImage
          src="../images/billboard-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={160}
          height={32}
        />
        <StaticImage
          src="../images/techcrunch-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={64}
          height={32}
        />
        <StaticImage
          src="../images/musicbusiness-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={144}
          height={32}
        />
        <StaticImage
          src="../images/variety-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={128}
          height={32}
        />
        <StaticImage
          src="../images/musicradar-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={160}
          height={32}
        />
        <StaticImage
          src="../images/daily-double-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={100}
          height={60}
        />
        <StaticImage
          src="../images/musictech-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={176}
          height={32}
        />
        <StaticImage
          src="../images/nme-logo.svg"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={96}
          height={32}
        />
        <StaticImage
          src="../images/cbs-logo.png"
          alt="rolling-list-logo"
          placeholder="blurred"
          layout="fixed"
          width={320}
          height={32}
        />
      </div>
    </div> */}

    <div className='marque-list inline-flex'>
      <div className='animate-toLeft inline-flex gap-x-24 pl-12 pr-12  items-center !w-max'>
        <img
          src={img1}
          alt="rolling-list-logo"
          width={160}
          height={32}
        />
        <img
          src={img2}
          alt="rolling-list-logo"
          width={64}
          height={32}
        />
        <img
          src={img3}
          alt="rolling-list-logo"
          width={144}
          height={32}
        />
        <img
          src={img4}
          alt="rolling-list-logo"
          width={128}
          height={32}
        />
        <img
          src={img5}
          alt="rolling-list-logo"
          width={160}
          height={32}
        />
        <img
          src={img6}
          alt="rolling-list-logo"
          width={100}
          height={60}
        />
        <img
          src={img7}
          alt="rolling-list-logo"
          width={176}
          height={32}
        />
        <img
          src={img8}
          alt="rolling-list-logo"
          width={96}
          height={32}
        />
        <img
          src={img9}
          alt="rolling-list-logo"
          width={320}
          height={32}
        />
      </div>
      <div className='animate-toLeft inline-flex gap-x-24 pl-12 pr-12  items-center !w-max'>
        <img
          src={img1}
          alt="rolling-list-logo"
          width={160}
          height={32}
        />
        <img
          src={img2}
          alt="rolling-list-logo"
          width={64}
          height={32}
        />
        <img
          src={img3}
          alt="rolling-list-logo"
          width={144}
          height={32}
        />
        <img
          src={img4}
          alt="rolling-list-logo"
          width={128}
          height={32}
        />
        <img
          src={img5}
          alt="rolling-list-logo"
          width={160}
          height={32}
        />
        <img
          src={img6}
          alt="rolling-list-logo"
          width={100}
          height={60}
        />
        <img
          src={img7}
          alt="rolling-list-logo"
          width={176}
          height={32}
        />
        <img
          src={img8}
          alt="rolling-list-logo"
          width={96}
          height={32}
        />
        <img
          src={img9}
          alt="rolling-list-logo"
          width={320}
          height={32}
        />
      </div>
    </div>
  </div>
);

export default RollingBrandsList;

