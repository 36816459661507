import React, { useState } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import * as styles from '../assets/css/post.module.css';

const query = graphql`
  query getAllPost {
    allWpPost (
      filter: {categories: {nodes: {elemMatch: {name: {in: ["BandLab", "BandLab Technologies", "Airbit", "Cakewalk", "ReverbNation"]}}}}},
      sort: { date: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          dateGmt(formatString: "DD MMMM YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: DOMINANT_COLOR
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
            }
          }
          tags {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`;

const AllPostList = () => {
  const data = useStaticQuery(query);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [displayedPosts, setDisplayedPosts] = useState(6);

  const categorySet = new Set(); // To store unique category names

  data.allWpPost.edges.forEach((edge) => {
    edge.node.categories.nodes.forEach((category) => {
      if (
        category.name === 'BandLab' ||
        category.name === 'BandLab Technologies' ||
        category.name === 'Airbit' ||
        category.name === 'Cakewalk' ||
        category.name === 'ReverbNation'
      ) {
        categorySet.add(category.name);
      }
    });
  });

  const uniqueCategories = Array.from(categorySet);

  // Find the index of "BandLab Technologies" in the uniqueCategories array
  const bltIndex = uniqueCategories.indexOf('BandLab Technologies');

  // Move "BandLab Technologies" to the front of the array if found
  if (bltIndex !== -1) {
    uniqueCategories.splice(bltIndex, 1); // Remove "BandLab Technologies" from its current position
    uniqueCategories.unshift('BandLab Technologies'); // Add "BandLab Technologies" to the beginning
  }

  const filteredPosts = selectedCategory
    ? data.allWpPost.edges.filter((edge) =>
      edge.node.categories.nodes.some(
        (category) => category.name === selectedCategory,
      ),
    )
    : data.allWpPost.edges;

  const loadMorePosts = () => {
    setDisplayedPosts(displayedPosts + 6);
  };

  return (
    <>
      <h3 className="mn:pl-[14.583vw] mn:mb-12 mb-4">More updates</h3>
      <div className="pb-[7.292vw] mn:pl-[14.583vw] relative">
        <div className='absolute left-0 top-0 max-w-[14.583vw] w-full px-4 smallLaptop:relative smallLaptop:max-w-[unset] smallLaptop:p-0'>
          <div className='pb-[3.125vw] flex flex-col text-white gap-[0.4rem] smallLaptop:!block smallLaptop:columns-2 smallLaptop:mb-4'>
            <label className='hover:bg-white hover:text-black sm:mb-[0.6rem] text-[13px] tracking-[2px] font-CM relative text-white flex items-center gap-x-[0.6rem] cursor-pointer pt-[2px] pr-[6px] pb-0 pl-0 rounded-[50px] duration-300 md:mb-[0.6rem] uppercase w-fit'>
              <input
                type="radio"
                value=""
                checked={!selectedCategory}
                onChange={() => setSelectedCategory('')}
                data-active={!selectedCategory}
              />
              Showing All
            </label>
            {uniqueCategories.map((categoryName) => (
              <label key={categoryName}  className='hover:bg-white hover:text-black sm:mb-[0.6rem] text-[13px] tracking-[2px] font-CM relative text-white flex items-center gap-x-[0.6rem] cursor-pointer pt-[2px] pr-[6px] pb-0 pl-0 rounded-[50px] duration-300 md:mb-[0.6rem] uppercase w-fit' >
                <input
                  type="radio"
                  value={categoryName}
                  checked={selectedCategory === categoryName}
                  onChange={() => setSelectedCategory(categoryName)}
                  data-active={selectedCategory === categoryName}
                />
                {categoryName === 'BandLab Technologies' ? 'BLT' : categoryName}
              </label>
            ))}
          </div>
        </div>
        {/* <div className={`${styles.allPostListHolder} gap-x-3 gap-y-16 flex flex-wrap`}> */}
        <div className='gap-x-3 gap-y-16 flex flex-wrap'>
          {filteredPosts.slice(0, displayedPosts).map((edge) => {
            const {
              id,
              title,
              featuredImage,
              dateGmt,
              slug,
              categories,
            } = edge.node;

            if (
              featuredImage &&
              featuredImage.node &&
              featuredImage.node.localFile.childImageSharp
            ) {
              const image = getImage(
                featuredImage.node.localFile.childImageSharp.gatsbyImageData,
              );
              const altText = title;

              return (
                <div className={`${styles.individualBlog} group`} key={id}>
                  <Link
                    to={`/news/${slug}`}
                    className={`${styles.hasArrow} relative`}
                  >
                    <GatsbyImage
                      image={image}
                      alt={altText}
                      className={`${styles.blogImgHolder} mb-12`}
                    />
                    <h6 className="mb-3">{dateGmt}</h6>
                    <h4 className='[font-size:clamp(20px,1.458vw,28px)] font-BLT font-medium tracking-[-1px] leading-[1.3]'>{title}</h4>
                    <div className="flex gap-x-3 gap-y-1 absolute top-6 left-6 z-10 flex-wrap pr-12 sm:!hidden opacity-0 group-hover:opacity-100">
                      {categories.nodes.length > 0
                        ? categories.nodes.map((item, index) => {
                          if(item.name === 'BandLab' || item.name === 'Airbit' || item.name === 'BandLab Technologies' || item.name === 'Cakewalk' || item.name === 'ReverbNation') {

                            return (
                              <h6 key={index} className="borderedTitle !mb-0">
                                {item.name}
                              </h6>
                            );
                          }
                          return null;
                        },
                        )
                        : ''}
                    </div>
                  </Link>
                </div>
              );
            }

            // Return null if the image data is not available
            return (
              <div className={`${styles.individualBlog} group`} key={id}>
                <Link
                  to={`/news/${slug}`}
                  className={`${styles.hasArrow} relative`}
                >
                  <StaticImage
                    src="../images/blt-post-placeholder.jpg"
                    alt="post-placeholder"
                    placeholder="dominantColor"
                    layout="fullWidth"
                    className={`${styles.blogImgHolder} mb-12`}
                  />
                  <h6 className="mb-3">{dateGmt}</h6>
                  <h4 className='[font-size:clamp(20px,1.458vw,28px)] font-BLT font-medium tracking-[-1px] leading-[1.3]'>{title}</h4>
                  <div className="flex gap-x-3 gap-y-1 absolute top-6 left-6 z-10 flex-wrap pr-12 sm:!hidden opacity-0 group-hover:opacity-100">
                    {categories.nodes.length > 0
                      ? categories.nodes.map((item, index) => (
                        <h6 key={index} className="borderedTitle !mb-0">
                          {item.name}
                        </h6>
                      ))
                      : ''}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>

        {displayedPosts < filteredPosts.length && (
          <button className="load-more-button" onClick={loadMorePosts}>
            LOAD MORE ARTICLES
          </button>
        )}
      </div>
    </>
  );
};

export default AllPostList;
