import React, { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/all';
import Layout from '../components/layout';
import Seo from '../components/seo';
import * as styles from '../assets/css/career.module.css';
import CareerList from '../components/careerList';
import ArrowButton from '../components/arrowButton';
import careerBannerImg from '../images/career-banner-img.jpg';

const Careers = () => {
  useEffect(() => {
    // Refresh GSAP animations every 3 seconds
    const refreshInterval = setInterval(() => {
      ScrollTrigger.refresh();
    }, 3000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout>
      <section className="bg-black pt-52">
        <div className=" relative z-10">
          <h6 className=" mt-5 mn:absolute text-clip md:mb-4">Careers</h6>
          {/* Top Title */}
          {/* <h1 className={`${styles.headingImg} flex flex-col w-full md:mb-8 line-split mask-anim`}> */}
          <h1 className={`${styles.headingImg} flex flex-col md:mb-8 w-fit relative line-split mask-anim`}>
              Shape the future of music with us
          </h1>
        </div>

        {/* Image Desc Container */}
        <div
          className='flex justify-between items-end -mt-20 pr-[1.042vw] pl-[27.604vw] ipad:mt-[-20px] md:flex-col md:items-start md:p-0 md:m-0 md:mb-16'
        >
          <img src={careerBannerImg} alt="" className='w-[28.125vw] md:w-[65%] md:mb-8 sm:w-[100%]' />
          <div className='text-clip max-w-[23.177vw] md:max-w-[50vw] sm:max-w-[unset]'>
            <h6 className=" font-medium mb-7">
              NO MATTER WHERE YOU ARE IN THE WORLD, HERE’S WHERE YOU CAN PLAY A
              PART IN EMPOWERING A NEW GENERATION OF MUSIC LOVERS ALONGSIDE A
              DIVERSE, PASSIONATE TEAM.
            </h6>
            <ArrowButton linkTo="#careers" text="VIEW ROLES" />
          </div>
        </div>
      </section>

      {/* Import Career Lists */}
      <section className="bg-black px-0 md:!pl-0 md:!pr-0" id="careers">
        <CareerList />
      </section>
    </Layout>
  );
};

export const Head = () => (
  <Seo
    title="Careers"
    description="Be part of a team that's redefining music technology. Discover rewarding career paths at BandLab Technologies and help us build the future of music innovation."
  />
);

export default Careers;
