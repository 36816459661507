// extracted by mini-css-extract-plugin
export var active = "header-module--active--26582";
export var arrowButton = "header-module--arrowButton--e5879";
export var biggerLogo = "header-module--biggerLogo--6b0ab";
export var hideLogo = "header-module--hideLogo--98bf2";
export var individualBrands = "header-module--individualBrands--72e34";
export var menuBrandsList = "header-module--menuBrandsList--f4835";
export var menuChild = "header-module--menuChild--e2f12";
export var menuLinks = "header-module--menuLinks--49c41";
export var mobileBottomMenu = "header-module--mobileBottomMenu--d72e6";
export var mobileContactMenu = "header-module--mobileContactMenu--fe020";
export var mobileMainMenu = "header-module--mobileMainMenu--41584";
export var mobileMenu = "header-module--mobileMenu--4ad87";
export var topNav = "header-module--topNav--74c55";
export var topNavLink = "header-module--topNavLink--1230c";