import React, { useEffect, useState } from 'react';

const AnimatedText = ({ text }) => {
  // State to store the animated text
  const [animatedText, setAnimatedText] = useState('');

  useEffect(() => {
    // Add a timeout of 1 second before starting the animation
    const timeout = setTimeout(() => {
      const theLetters = 'abcdefghijklmnopqrstuvwx'; // You can customize what letters it will cycle through
      const ctnt = text; // Your text goes here
      const speed = 10; // ms per frame
      const increment = 7; // frames per step. Must be >2

      const clen = ctnt.length;
      let si = 0;
      let stri = 0;
      let block = '';
      let fixed = '';

      // Call self x times, whole function wrapped in setTimeout
      (function rustle(i) {
        setTimeout(() => {
          if (--i) {
            rustle(i);
          }
          nextFrame(i);
          si += 1;
        }, speed);
      })(clen * increment + 1);

      function nextFrame(pos) {
        for (let i = 0; i < clen - stri; i++) {
          // Random number
          const num = Math.floor(theLetters.length * Math.random());
          // Get random letter
          const letter = theLetters.charAt(num);
          block += letter;
        }
        if (si === increment - 1) {
          stri++;
        }
        if (si === increment) {
          // Add a letter; every speed*10 ms
          fixed += ctnt.charAt(stri - 1);
          si = 0;
        }
        setAnimatedText(fixed + block);
        block = '';
      }
    }, 800); // Timeout of 1 second

    // Clear the timeout if the component unmounts or if the text changes
    return () => clearTimeout(timeout);
  }, [text]);
  return (
    <div>
      <p id="output" className='m-0 font-BLT font-medium text-[2.865vw] leading-[1.1]'>{animatedText}</p>
    </div>
  );
};

export default AnimatedText;
