import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import * as styles from '../assets/css/contact.module.css';
import contactBanner from '../images/contact-banner-img.jpg';

const Contact = () =>
  (
    <Layout>
      <section className="bg-black pt-52 pb-[9.375vw] sm:pb-10">
        <div className=" relative z-10">
          <h6 className=" mt-5 mn:absolute text-clip md:mb-4">Contact</h6>
          <h1 className={`${styles.headingImg} flex flex-col w-full md:mb-8 mn:pr-[7vw] line-split mask-anim`}>
            Let's build the future of music together
          </h1>
        </div>

        {/* Image Desc Container */}
        <div className='flex items-center -mt-20 gap-x-[4.5vw] pr-[9.375vw] pl-[27.604vw] smallLaptop:pr-[1.042vw] smallLaptop:pl-[13.710vw] lg:mt-8 md:flex-col md:items-start md:p-0'>
          <img src={contactBanner} alt="" />
          <div className='max-w-[23.177vw] md:w-full md:max-w-[unset] md:mb-16'>
            <div className='mb-12'>
              <h6 className="borderedTitle mb-4">General Enquiries</h6>
              <div className="flex flex-col">
                <a
                  href="mailto:info@bandlabtechnologies.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-clip noStyle"
                >
                  <h6>info@bandlabtechnologies.com</h6>
                </a>
              </div>
            </div>
            <div>
              <h6 className="borderedTitle mb-4">FOR PR</h6>
              <div className="flex flex-col">
                <a
                  href="mailto:press@bandlabtechnologies.com"
                  target="_blank"
                  rel="noreferrer"
                  className="text-clip noStyle"
                >
                  <h6>press@bandlabtechnologies.com</h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
;

export const Head = () => (
  <Seo
    title="Contact Us"
    description="Reach out to BandLab Technologies to start a conversation about collaborations, partnerships, or any queries related to our innovative brands."
  />
);

export default Contact;
