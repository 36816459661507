import React from 'react';

import './src/assets/css/global.css';

export const onRouteUpdate = () => {
  // GA and other page tracking
  const sendPageView = () => {
    (function gaTracking() {
      if (window.gtag) {
        const pagePath = window.location
          ? window.location.pathname + window.location.search + window.location.hash
          : undefined;

        window.gtag('event', 'page_view', {
          page_path: pagePath,
        });
      }
    })();
  };

  if ('requestAnimationFrame' in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }
};
