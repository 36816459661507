import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import WebLogo from '../images/bandlabtechnologies-logo.svg';
import * as styles from '../assets/css/header.module.css';
import DummyArrowButton from './dummyArrowButton';
import BandlabLogo from '../images/bandlab-big-white-logo.svg';
import CakewalkLogo from '../images/cakewalk-logo.svg';
import ReverbnationLogo from '../images/reverb-nation-logo.svg';
import AirbitLogo from '../images/airbit-logo.svg';

const Header = () => {
  const [headerIsTop, setHeaderIsTop] = useState(false);
  const [headerLight, setHeaderLight] = useState(false);
  const [brandsMenuVisible, setBrandsMenuVisible] = useState(false);

  const [mobileActive, setMobileActive] = useState(false);
  const [brandsDrpDwn, setBrandsDrpDwn] = useState(false);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY === 0) {
        setHeaderIsTop(false);
      } else {
        setHeaderIsTop(true);
      }
    };

    gsap.registerPlugin(ScrollTrigger);

    const sections = gsap.utils.toArray('.bright');

    sections.forEach((section) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: '-10% top',
          end: '90% top',
          scrub: true,
          markers: false,
          toggleClass: 'active-light',
          onEnter() {
            setHeaderLight(true);
          },
          onLeave() {
            setHeaderLight(false);
          },
          onEnterBack() {
            setHeaderLight(true);
          },
          onLeaveBack() {
            setHeaderLight(false);
          },
        },
      });
    });

    // eslint-disable-next-line no-return-assign
    return () => (window.onscroll = null);
  });

  const handleBrandsMenuHover = () => {
    setBrandsMenuVisible(true);
  };

  const handleBrandsMenuLeave = () => {
    setBrandsMenuVisible(false);
  };

  const handleMobileMenu = () => {
    setMobileActive(!mobileActive);
  };

  const handleBrandsDrpDwn = () => {
    setBrandsDrpDwn(!brandsDrpDwn);
  };
  return (
    <header className='fixed pt-[30px] px-[20px] top-0 left-0 w-full z-[999]'>
      <div
        className={`${styles.topNav} ${headerLight ? 'lightHeader' : ''
          } md:!hidden flex w-[76%] justify-between items-center`}
      >
        <Link to="/" className={styles.topNavLink}>Home</Link>
        <Link
          to="#"
          onMouseEnter={handleBrandsMenuHover}
          onMouseLeave={handleBrandsMenuLeave}
          className={`${styles.topNavLink} !bg-transparent !text-white hover:!bg-white hover:!text-black`}
        >
          Brands
        </Link>
        <Link to="/news/" className={styles.topNavLink}>News</Link>
        <Link to="/careers/" className={styles.topNavLink}>Careers</Link>
        <Link to="/team/" className={styles.topNavLink}>Team</Link>
        <Link to="/contact/" className={styles.topNavLink}>Contact</Link>
        <Link to="/" className={`${'headerLogo'} ${headerIsTop ? 'active' : ''} ${brandsMenuVisible ? `${styles.hideLogo}` : ''}`}>
          <img
            src={WebLogo}
            alt="BandLab Technologies Logo"
          />
        </Link>
      </div>
      <div
        className={`${styles.menuBrandsList} menuBrandsList ${brandsMenuVisible ? 'active' : ''} md:!hidden`}
        onMouseEnter={handleBrandsMenuHover}
        onMouseLeave={handleBrandsMenuLeave}
        role="presentation"
      >
        <Link className={`${styles.individualBrands}`} to="/brands/bandlab/">
          <div>
            <img
              src={BandlabLogo}
              alt="brand-logo"
              className='w-[12.375vw] h-full flex justify-center'
            />
            <h6 className="borderedTitle">SOCIAL MUSIC PLATFORM</h6>
            <DummyArrowButton linkTo="/brands/bandlab/" text="Learn More" />
          </div>
        </Link>
        <Link className={`${styles.individualBrands}`} to="/brands/cakewalk/">
          <div>
            <img
              src={CakewalkLogo}
              alt="cakewalk-logo"
              className='w-[12.375vw] h-full flex justify-center'
            />
            <h6 className="borderedTitle">ADVANCED MUSIC TOOLS</h6>
            <DummyArrowButton linkTo="/brands/cakewalk/" text="Learn More" />
          </div>
        </Link>
        <Link
          className={`${styles.individualBrands} ${styles.biggerLogo}`}
          to="/brands/reverbnation/"
        >
          <div>
            <img
              src={ReverbnationLogo}
              alt="reverbnation-logo"
              className='w-[16.375vw] h-full flex justify-center'
            />
            <h6 className="borderedTitle">POWERFUL ARTIST SERVICES</h6>
            <DummyArrowButton
              linkTo="/brands/reverbnation/"
              text="Learn More"
            />
          </div>
        </Link>
        <Link className={`${styles.individualBrands}`} to="/brands/airbit/">
          <div>
            <img
              src={AirbitLogo}
              alt="airbit-logo"
              className='w-[12.375vw] h-full flex justify-center'
            />
            <h6 className="borderedTitle">GLOBAL BEAT MARKETPLACE</h6>
            <DummyArrowButton linkTo="/brands/airbit/" text="Learn More" />
          </div>
        </Link>
      </div>

      {/* Mobile Menu */}
      <Link to="/">
        <img
          className={`headerLogo ${headerIsTop ? 'active' : ''} mn:hidden`}
          src={WebLogo}
          alt="Menu Brand Logo"
        />
      </Link>
      <div
        className={`mobileMenuBtn ${mobileActive ? 'active' : ''} ${headerLight ? 'lightHeader' : ''
          } mn:opacity-0 mn:pointer-events-none fixed top-[2.85rem] right-[15px] w-[40px] h-[25px] flex flex-col justify-between items-end z-[999] before:hidden`}
        onClick={handleMobileMenu}
        role="presentation"
      >
        <span className='w-[33%] h-[3px] bg-white duration-300'></span>
        <span className='w-[66%] h-[3px] bg-white duration-300'></span>
        <span className='w-full h-[3px] bg-white duration-300'></span>
      </div>
      {mobileActive && (
        <div className={`${styles.mobileMenu}`}>
          <div className={`${styles.mobileMainMenu}`}>
            <Link to="/" className={`${styles.menuLinks}`}>Home</Link>
            <div className={`${brandsDrpDwn ? `${styles.active}` : ''}`}>
              <p className={`${styles.menuLinks}`} onClick={handleBrandsDrpDwn} role="presentation"><span className='relative'>Brands</span></p>
              <div className={`${styles.menuChild}`}>
                <Link to="/brands/bandlab/" className={`${styles.menuLinks}`}>BandLab</Link>
                <Link to="/brands/cakewalk/" className={`${styles.menuLinks}`}>Cakewalk</Link>
                <Link to="/brands/reverbnation/" className={`${styles.menuLinks}`}>ReverbNation</Link>
                <Link to="/brands/airbit/" className={`${styles.menuLinks}`}>Airbit</Link>
              </div>
            </div>
            <Link to="/team/" className={`${styles.menuLinks}`}>Team</Link>
            <Link to="/news/" className={`${styles.menuLinks}`}>News</Link>
            <Link to="/careers/" className={`${styles.menuLinks}`}>Careers</Link>
            <Link to="/contact/" className={`${styles.menuLinks}`}>Contact</Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
