import React, { useEffect } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Layout from '../components/layout';
import AllPostList from '../components/allPostList';
import Seo from '../components/seo';
import * as styles from '../assets/css/post.module.css';
import SinglePost from '../components/singlePost';

const News = () => {

  useEffect(() => {
    // Refresh GSAP animations every 3 seconds
    const refreshInterval = setInterval(() => {
      ScrollTrigger.refresh();
    }, 3000);

    // Cleanup function to clear the interval when the component unmounts
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <Layout>
      <section className="bg-black pt-52">
        <div className=" relative z-10">
          <h6 className=" mt-5 mn:absolute text-clip md:mb-4">News</h6>
          <h1 className={`${styles.headingImg} flex flex-col w-full md:mb-8 line-split mask-anim mn:headerSpace:ml-[12.240vw]`}>
            Don’t miss a beat
          </h1>
        </div>
      </section>

      {/* Import Featured Post */}
      <section className="bg-black pl-[14.583vw]">
        <SinglePost />
      </section>

      {/* Import AllPost Lists */}
      <section className="bg-black pl-0">
        <AllPostList />
      </section>
    </Layout>
  );
};

export const Head = () => (
  <Seo
    title="Latest News"
    description="Stay informed with the latest news and updates from BandLab Technologies. Explore the advancements, innovations, and exciting developments across our digital brands - BandLab, Cakewalk, ReverbNation, and Airbit."
  />
);

export default News;
