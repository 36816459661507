import React, { useRef, useLayoutEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from './layout';
import BrandsListing from './brandsListing';
import RecentPostList from './recentPostList';
import VisionSlider from './visionSlider';
import * as styles from '../assets/css/index.module.css';
import RollingBrandsList from './rollingBrandsList';

const HomePageComponent = () => {

  const heroBigReveal = useRef(null);
  const heroSmallTitle = useRef(null);
  const heroRightContent = useRef(null);
  const heroSectionRef = useRef(null);
  const imgDescRef = useRef(null);

  // Use useLayoutEffect to ensure refs are available for GSAP
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const bigRevealRef = heroBigReveal.current;
    const smallTitleRef = heroSmallTitle.current;
    const rightContentRef = heroRightContent.current;
    const imgDescRefCurrent = imgDescRef.current;

    gsap.fromTo(
      '.big-text-reveal span',
      {
        yPercent: 115,
      },
      {
        yPercent: 0,
        stagger: 0.25,
      },
      '+=0.05',
    );

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: heroSectionRef.current,
        start: 'top top',
        end: '75% top',
        scrub: 1,
        toggleActions: 'play reverse play reverse',
        ease: 'none',
      },
    });

    if (window.innerWidth > 969) {
      tl.to(heroBigReveal.current, { yPercent: -20 })
        .to(heroSmallTitle.current, { y: -200 }, '<')
        .to(heroRightContent.current, { yPercent: 150 }, '<');
    }

    return () => {
      // Clean up the animations and ScrollTriggers
      gsap.killTweensOf(bigRevealRef.children);
      gsap.killTweensOf(bigRevealRef);
      gsap.killTweensOf(smallTitleRef);
      gsap.killTweensOf(rightContentRef);
      gsap.killTweensOf(imgDescRefCurrent.children[0].children[1]);

      ScrollTrigger.getAll().forEach((trigger) => {
        trigger.kill();
      });
    };
  }, []);

  return (
    <Layout>
      <section
        className={`${styles.homeHeroSection} h-[max(96.5vh,96.5vh)] bg-black flex items-end z-10 ipad:min-h-[60vh] md:items-start md:flex-col md:justify-center min-h-[unset] md:pt-[180px] md:mb-16`}
        ref={heroSectionRef}
      >
        {/* Big Hero Title */}
        <div className='flex flex-col gap-x-[5.729vw] mb-8'>
          <h6 className="text-clip md:mb-8" ref={heroSmallTitle}>
            A FUTURE OF
          </h6>
          <h1
            // className={`${styles.bigTitle} big-text-reveal [font-size:clamp(64px,12.240vw,12.240vw)] leading-[1.1] flex flex-col w-fit text-white mt-[1.042vw] font-medium [letter-spacing:clamp(-2px,0.365vw,-7px)] ipad:mb-16 md:mb-12`}
            className='big-text-reveal [font-size:clamp(64px,12.240vw,12.240vw)] leading-[1.1] flex flex-col w-fit text-white mt-[1.042vw] font-medium [letter-spacing:clamp(-2px,0.365vw,-7px)] ipad:mb-16 md:mb-12'
            ref={heroBigReveal}
          >
            <div className="line">
              <span>
                Music
                {/* <div id="bandlab-lottie-logo-animation"></div> */}
                <div className='brandRotate'></div>
              </span>
            </div>
            <div className="line">
              <span>without</span>
            </div>
            <div className="line">
              <span>boundaries</span>
            </div>
          </h1>
        </div>

        {/* Right Floating Side Content */}
        <h6
          className="flex flex-col float-none ml-auto w-fit self-center max-w-[11.979vw] lg:!max-w-none md:!max-w-[330px] md:ml-0 md:mr-auto text-clip"
          ref={heroRightContent}
        >
          WE EMPOWER CREATORS AT EVERY STAGE IN THEIR MUSIC JOURNEY.
        </h6>

        {/* <div id="lottie-header-animation" /> */}
      </section>

      {/* Description With Images Section */}
      <section
        className="bg-black h-screen pl-left pr-0 img-desc-section flex flex-col gap-y-24 justify-center ipad:min-h-0 ipad:pt-24 ipad:pb-24 md:pt-0 ipad:h-auto"
        ref={imgDescRef}
      >
        {/* Top Container */}
        <div className="top-container">
          <div className="left-desc">
            {/* Desktop Text */}
            <h3 className="flex flex-col line-fade-in md:!hidden w-fit">
              <span className="flex items-center justify-between">
                <span className="paraSmallTitle">OUR MISSION</span>To shape the future of
              </span>
              <span className="span-with-img">
                music by building tools for
                <StaticImage
                  src="../images/down-arrows-img.svg"
                  alt="bandlab-logo"
                  placeholder="dominantColor"
                  layout="constrained"
                  className="fade-in"
                  width={100}
                />
              </span>
              <span>a new generation of music </span>
              <span>creators and fans.</span>
            </h3>

            {/* Mobile Text */}
            <h3 className="flex-col line-fade-in hidden md:flex w-fit">
              <span className="flex items-center justify-between md:flex-col md:items-start">
                <span className="paraSmallTitle md:flex">OUR MISSION</span>To shape the future of
              </span>
              <span>music by building tools for</span>
              <span>a new generation of music</span>
              <span>creators and fans.</span>
            </h3>
          </div>
          <StaticImage
            src="../images/girl-img.jpg"
            alt="right-pic"
            placeholder="dominantColor"
            layout="constrained"
            className="right-image "
            width={550}
            height={550}
          />
        </div>
      </section>
      {/* Brand Listings */}
      <h2 className="heading-reveal our-brands-heading text-[7.292vw] md:text-[50px] px-[15px]">
        <div className="line">
          <span className='relative before:absolute before:right-0 before:top-0 before:bottom-0 before:w-auto before:h-[60%] before:my-auto before:mx-0 before:aspect-square before:translate-x-[140%] before:translate-y-[-15%]'>Our Brands</span>
        </div>
      </h2>
      <BrandsListing />

      <RollingBrandsList />

      {/* Vision Sliders */}
      <section className="!px-0">
        <VisionSlider />
      </section>

      {/* Recent Posts */}
      <section className="bright bg-white flex flex-col justify-start pt-32 pb-32">
        <div className="relative w-fit flex gap-x-8">
          <Link to="/news/">
            <h2 className="text-black mb-4 heading-reveal">
              <div className="line">
                <span className="newsUpdatesText">Latest news</span>
              </div>
            </h2>
          </Link>
        </div>
        <RecentPostList />
      </section>
    </Layout>
  );
};

export default HomePageComponent;
