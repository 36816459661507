import React from 'react';

import * as Select from '@radix-ui/react-select';

export function SelectTrigger({ placeholder, label }) {
  return (
    <Select.Trigger
      className="text-black text-base font-medium rounded-full uppercase ps-2 pe-1 w-40 appearance-none focus-visible:ring-1 focus-visible:ring-red outline-none bg-white justify-between flex items-center"
      aria-label={label}
    >
      <div className="overflow-clip whitespace-nowrap font-CM">
        <Select.Value placeholder={placeholder} />
      </div>
      <Select.Icon className="text-black">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          className="w-6"
        >
          <polyline points="6 9 12 15 18 9"></polyline>
        </svg>
      </Select.Icon>
    </Select.Trigger>
  );
}

export function SelectContent({ children }) {
  return (
    <Select.Portal>
      <Select.Content className="overflow-clip z-[999] bg-white p-1 rounded-lg">
        <Select.ScrollUpButton className="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="w-4 rotate-180"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </Select.ScrollUpButton>
        <Select.Viewport>{children}</Select.Viewport>
        <Select.ScrollDownButton className="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="w-4"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  );
}

export function SelectItem({ text, value }) {
  return (
    <Select.Item
      value={value}
      className="px-2 py-1 rounded-lg outline-none data-[highlighted]:bg-red  data-[highlighted]:text-white"
    >
      <Select.ItemText>{text}</Select.ItemText>
    </Select.Item>
  );
}
