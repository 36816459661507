import React from 'react';
import Seo from '../components/seo';
import HomePageComponent from '../components/homePageComponent';
import Preloader from '../components/preloader';

const IndexPage = () => (
  <Preloader>
    <HomePageComponent />
  </Preloader>
);

/**
 * Reference: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="Music Without Boundaries"
    description="BandLab Technologies is a collective of global music technology companies that envisions a future where there are no boundaries to making and sharing music."
  />
);

export default IndexPage;
