// extracted by mini-css-extract-plugin
export var blogImgHolder = "post-module--blogImgHolder--d943a";
export var blogWrapper = "post-module--blogWrapper--be644";
export var fadeIn = "post-module--fadeIn--b01ff";
export var hasArrow = "post-module--hasArrow--16295";
export var headingImg = "post-module--headingImg--50e84";
export var individualBlog = "post-module--individualBlog--9d956";
export var postContentHolder = "post-module--postContentHolder--87c99";
export var postContentSection = "post-module--postContentSection--235bd";
export var postDateCat = "post-module--postDateCat--9e2d6";
export var postFeaturedImgHolder = "post-module--postFeaturedImgHolder--122c0";
export var postImgFade = "post-module--postImgFade--096fc";
export var postShareHolder = "post-module--postShareHolder--d054b";
export var postTopSection = "post-module--postTopSection--c2366";
export var relatedPost = "post-module--relatedPost--6f857";