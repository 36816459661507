import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import BrandsListing from '../../components/brandsListing';
import Counter from '../../components/counter';
import ExternalLinkArrowButton from '../../components/externalLinkArrowButton';
import BandlabImg from '../../images/bandlab-big-white-logo.svg';
import BandlabImgSmall from '../../images/bandlab-kf-logo.png';
import BrandStatsImage from '../../images/bandlab-banner-2.jpg';
import RightImage from '../../images/bandlab-banner-1.jpg';

const Bandlab = () => (
  <Layout>
    <section className='pt-[200px] px-[14.583vw] pb-0 z-[1]'>
      <h6 className="mn:!hidden mb-6">SOCIAL MUSIC CREATION NETWORK</h6>
      <div className="initial-heading-reveal img-reveal">
        <div className="line">
          <span>
            <img
              src={BandlabImg}
              alt="airbit-big-logo"
              className='xlg:max-w-[37.583vw] md:max-w-[60vw]'
              width={760}
            />
          </span>
        </div>
      </div>
    </section>

    <section className='md:!pb-16 sm:!pb-0 md:pt-16 pt-[8.333vw] pl-[14.583vw] pr-0 pb-0 flex justify-between'>
      <div className='flex flex-col gap-y-12 md:gap-y-6'>
        {/* Desktop Title View */}
        <h3 className='line-fade-in initial md:!hidden flex flex-col'>
          <span className="flex items-center justify-between">
            <span className="paraSmallTitle md:hidden">
            SOCIAL MUSIC CREATION NETWORK
            </span>
            A creator-first,
          </span>
          <span className="span-with-img">
          destination where anyone
            <img
              src={BandlabImgSmall}
              alt="bandlab-small-logo"
              className='!w-[2.604vw] !object-contain'
            />
          </span>
          <span>with access to the internet</span>
          <span>can make music, grow their</span>
          <span>audience and earn a living.</span>
        </h3>
        {/* Mobile Title View */}
        <div className='relative max-w-[500px] mn:!hidden'>
          <h3 className='line-split mask-anim !flex-row'>
            A creator-first, destination where anyone with access to the internet can make music, grow their audience and earn a living.
          </h3>
        </div>
        <ExternalLinkArrowButton
          linkTo="https://www.bandlab.com/"
          text="Visit BandLab Website"
          target="blank"
        />
      </div>
      <img
        src={RightImage}
        alt="right-desc-img"
        className='md:!hidden aspect-square w-[21.875vw] h-full'
      />
    </section>

    <section className='relative flex items-center justify-between min-h-[56.25vw] py-[160px] px-[14.583vw] gap-y-12 md:!justify-center md:flex-wrap sm:flex-col md:pt-[100px]'>
      <div className="absolute w-full h-1/4 bg-gradient-to-t from-black via-black-opacity-35 to-transparent bottom-0 left-0 -z-[1] pointer-events-none"></div>
      <div className="music-bg-img"></div>
      <div className='allChild:text-[var(--white)] allChild:m-0 allChild:relative allChild:z-[2] md:w-[50%] md:text-center sm:w-full sm:text-left'>
        <p className='text-[6.51vw] sm:text-[60px] font-medium leading-[1]'>
          <Counter targetValue="100" duration="1.5" />M+
        </p>
        <h5>Registered Users</h5>
      </div>
      {/* StatsHolder */}
      <div className='allChild:text-[var(--white)] allChild:m-0 allChild:relative allChild:z-[2] md:w-[50%] md:text-center sm:w-full sm:text-left'>
        <p className='text-[6.51vw] sm:text-[60px] font-medium leading-[1]'>
          <Counter targetValue="180" duration="1.5" />K+
        </p>
        <h5>Ratings on App Store</h5>
      </div>
      {/* StatsHolder */}
      <div className='allChild:text-[var(--white)] allChild:m-0 allChild:relative allChild:z-[2] md:w-[50%] md:text-center sm:w-full sm:text-left'>
        <p className='text-[6.51vw] sm:text-[60px] font-medium leading-[1]'>
          <Counter targetValue="360" duration="1.5" />K+
        </p>
        <h5>Ratings on Play Store</h5>
      </div>
      <img
        src={BrandStatsImage}
        alt="right-desc-img"
        className='absolute top-0 bottom-0 left-0 right-0 m-auto w-[34.896vw] h-auto aspect-square z-[-1] sm:relative sm:w-full'
      />
    </section>

    <section className="!px-0 pb-[9.375vw]">
      <BrandsListing currentBrand="bandlab" />
    </section>

  </Layout>
);

export const Head = () => (
  <Seo
    title="BandLab"
    description="The cloud platform where musicians and fans create music, collaborate, and engage with each other across the globe."
  />
);

export default Bandlab;
