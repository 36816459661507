import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import * as styles from '../assets/css/team.module.css';
import ArrowButton from '../components/arrowButton';
import Counter from '../components/counter';
import contactCtaImg from '../images/contact-section-img.jpg';

const locations = [
  'Australia', 'Azerbaijan', 'Belarus', 'Canada', 'Croatia', 'Czech Republic',
  'France', 'Georgia', 'Germany', 'Hong Kong', 'India', 'Indonesia',
  'Iran', 'Italy', 'Japan', 'Kazakhstan', 'Lithuania', 'Macedonia', 'Malaysia', 'Mauritius',
  'Mexico', 'Mongolia', 'Montenegro', 'Morocco', 'Myanmar', 'Netherlands', 'Nigeria',
  'Pakistan', 'Philippines', 'Poland', 'Portugal', 'Romania', 'Russia',
  'Serbia', 'Singapore', 'Spain', 'Taiwan', 'Thailand', 'Turkey', 'Ukraine',
  'United Arab Emirates', 'United Kingdom', 'United States', 'Uzbekistan', 'Vietnam',
];

const Team = () => (
  <Layout>
    <section className="bg-black pt-52 pb-24 md:pb-8">
      <div className=" relative z-10">
        <h6 className=" mt-5 mn:!absolute text-clip md:mb-5">Team</h6>
        <h1 className={`${styles.headingImg} flex flex-col md:mb-8 mn:pr-[7vw] line-split mask-anim relative w-fit`}>
          The people behind BandLab Technologies
        </h1>
      </div>
    </section>

    <section className='px-[12.5vw] py-[5.208vw] sm:mt-[60px]'>
      <StaticImage src='../images/map-img.png'
        alt="map"
        placeholder="dominantColor"
        layout="fullWidth"
      />

      {/* Stats */}
      <div className='sm:flex sm:flex-row sm:absolute sm:top-[-80px] sm:gap-x-10 sm:flex-wrap'>
        <div className='flex flex-col w-fit absolute left-0 right-0 top-0 translate-x-[-100%] mx-auto sm:relative sm:translate-x-0 sm:m-0'>
          <p className='text-[6.510vw] leading-[1] mb-0 font-medium'><Counter targetValue="30" duration="1.5" />+</p>
          <h5 className='text-white'>Nationalities</h5>
        </div>
        <div className='flex flex-col w-fit absolute left-0 right-0 top-0 translate-x-[100%] mx-auto sm:relative sm:translate-x-0 sm:m-0'>
          <p className='text-[6.510vw] leading-[1] mb-0 font-medium'><Counter targetValue="200" duration="1.5" />+</p>
          <h5 className='text-white'>Team Members</h5>
        </div>
      </div>

      {/* Dots */}
      <div className='flex gap-y-3 gap-x-3 items-center w-fit h-fit absolute left-[-43%] right-0 top-[-6%] bottom-0 m-auto md:right-[12%]'>
        <div className=" block w-4 aspect-square rounded-full bg-red animate-dotglow"></div>
        <h5 className='text-white'>Los Angeles</h5>
      </div>
      <div className='flex flex-row-reverse gap-y-3 gap-x-3 items-center w-fit h-fit absolute left-[-12%] right-0 top-[-8%] bottom-0 m-auto md:right-[12%]'>
        <div className=" block w-4 aspect-square rounded-full bg-red animate-dotglow"></div>
        <h5 className='text-white'>Tangier</h5>
      </div>
      <div className='flex gap-y-3 gap-x-3 items-center w-fit h-fit absolute left-[-0.5%] right-0 top-[-30%] bottom-0 m-auto '>
        <div className=" block w-4 aspect-square rounded-full bg-red animate-dotglow"></div>
        <h5 className='text-white'>London</h5>
      </div>
      <div className='flex flex-row-reverse gap-y-3 gap-x-3 items-center w-fit h-fit absolute left-0 right-[-30%] top-[19%] bottom-0 m-auto md:right-[-50%]'>
        <div className="block w-4 aspect-square rounded-full bg-red animate-dotglow"></div>
        <h5 className='text-white'>Singapore</h5>
      </div>
      <div className='flex gap-y-3 gap-x-3 items-center w-fit h-fit absolute left-0 right-[-52%] top-[13%] bottom-0 m-auto md:right-[-50%]'>
        <div className="block w-4 aspect-square rounded-full bg-red animate-dotglow"></div>
        <h5 className='text-white'>Manila</h5>
      </div>
    </section>

    {/* Locations */}
    <section className='text-center pb-[5.208vw] px-[16.667vw] sm:pb-20'>
      <h5 className='text-white mb-10'>Our Team Spans the Globe</h5>
      <div className='flex flex-wrap gap-x-8 gap-y-4 justify-center sm:gap-y-2'>
        {locations.map((location, index) => (
          <p key={index} className="text-white font-medium mb-0 leading-[1.3]">{location}</p>
        ))}
      </div>
    </section>

    <section className='!px-0 flex items-center bg-red sm:flex-col sm:items-start sm:[& > div]:w-full'>
      <img src={contactCtaImg} alt="" />
      <div className='px-[11.458vw] w-[65%] flex flex-col gap-y-[1.6rem] sm:py-28 sm:px-8 sm:-order-1 sm:w-full'>
        <h2 className='md:text-[40px]'>Join the band today</h2>
        <ArrowButton linkTo="/careers/" text="VIEW OPEN POSITIONS" />
      </div>
    </section>
  </Layout>
);

export const Head = () => (
  <Seo
    title="Team"
    description="Get to know the talented individuals driving music innovation at BandLab Technologies. Meet the diverse team behind our brands - BandLab, Cakewalk, ReverbNation, and Airbit."
  />
);

export default Team;
