import React from 'react';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import BrandsListing from '../../components/brandsListing';
import Counter from '../../components/counter';
import ExternalLinkArrowButton from '../../components/externalLinkArrowButton';
import AirbitImg from '../../images/airbit-icon-v2-w-text-white.svg';
import AirbitSmallIcon from '../../images/airbit-icon-v2-red.svg';
import RightImage from '../../images/airbit-banner-right-img.jpg';
import BrandStatsImage from '../../images/airbit-stat-img.jpg';

const Airbit = () => (
  <Layout>
    <section className='pt-[200px] px-[14.583vw] pb-0 z-[1]'>
      <h6 className="mn:!hidden mb-6">GLOBAL BEAT MARKETPLACE</h6>
      <div className="initial-heading-reveal img-reveal">
        <div className="line">
          <span>
            <img
              src={AirbitImg}
              alt="airbit-big-logo"
              className='md:max-w-[72vw] xlg:max-w-[30vw]'
              width={760}
            />
          </span>
        </div>
      </div>
    </section>

    <section className='md:!pb-16 sm:!pb-0 md:pt-16 pt-[8.333vw] pl-[14.583vw] pr-0 pb-0 flex justify-between'>
      <div className='flex flex-col gap-y-12 md:gap-y-6'>
        {/* Desktop Title View */}
        <h3 className='line-fade-in initial md:!hidden flex flex-col'>
          <span className="flex items-center justify-between">
            <span className="paraSmallTitle md:hidden">
              GLOBAL BEAT MARKETPLACE
            </span>
            Global
          </span>
          <span className="span-with-img">
            community-driven music {' '}
            <img
              src={AirbitSmallIcon}
              alt="airbit-small-logo"
              className='!w-[2.604vw] !object-contain'
            />
          </span>
          <span>marketplace for creators </span>
          <span>to discover, be discovered</span>
          <span>and collaborate.</span>
        </h3>
        {/* Mobile Title View */}
        <div className='relative max-w-[500px] mn:!hidden'>
          <h3 className='line-split mask-anim !flex-row'>
            Global community-driven music marketplace for creators to discover, be discovered and collaborate.
          </h3>
        </div>
        <ExternalLinkArrowButton
          linkTo="https://airbit.com/"
          text="VISIT AIRBIT WEBSITE"
          target="blank"
        />
      </div>
      <img
        src={RightImage}
        alt="right-desc-img"
        className='md:!hidden aspect-square w-[21.875vw] h-full'
      />
    </section>

    <section className='relative flex items-center justify-between min-h-[56.25vw] py-[160px] px-[14.583vw] gap-y-12 md:!justify-center md:flex-wrap sm:flex-col md:pt-[100px]'>
      <div className="absolute w-full h-1/4 bg-gradient-to-t from-black via-black-opacity-35 to-transparent bottom-0 left-0 -z-[1] pointer-events-none"></div>
      <div className="music-bg-img"></div>
      {/* StatsHolder */}
      <div className='allChild:text-[var(--white)] allChild:m-0 allChild:relative allChild:z-[2] md:w-[50%] md:text-center sm:w-full sm:text-left'>
        <p className='text-[6.51vw] sm:text-[60px] font-medium leading-[1]'>
          <Counter targetValue="2" duration="1.5" />M+
        </p>
        <h5>beats on Airbit marketplace</h5>
      </div>
      {/* StatsHolder */}
      <div className='allChild:text-[var(--white)] allChild:m-0 allChild:relative allChild:z-[2] md:w-[50%] md:text-center sm:w-full sm:text-left'>
        <p className='text-[6.51vw] sm:text-[60px] font-medium leading-[1]'>
          <Counter targetValue="50" duration="1.5" />M+
        </p>
        <h5>paid out to producers on Airbit</h5>
      </div>
      <img
        src={BrandStatsImage}
        alt="right-desc-img"
        className='absolute top-0 bottom-0 left-0 right-0 m-auto w-[34.896vw] h-auto aspect-square z-[-1] sm:relative sm:w-full'
      />
    </section>

    <section className="!px-0 pb-[9.375vw]">
      <BrandsListing currentBrand="airbit" />
    </section>
  </Layout>
);

export const Head = () => (
  <Seo
    title="Airbit"
    description="Airbit is a global platform made for independent music makers to buy, sell and collaborate on beats."
  />
);

export default Airbit;
