import React, { useState, useEffect } from 'react';
import { gsap } from 'gsap';
import AnimatedText from './animatedText';

const Preloader = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem('hasVisited');

    // If the user has visited before, skip the preloader
    if (hasVisitedBefore) {
      setIsLoading(false);
      return;
    }

    // Simulate a 5-second delay
    const preloaderTimer = setTimeout(() => {
      setIsLoading(false);
      localStorage.setItem('hasVisited', 'true');
    }, 3800);

    return () => clearTimeout(preloaderTimer);
  }, []);

  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo('.preloaderContainer', { opacity: 0 }, { opacity: 1 });

    setTimeout(() => {
      tl.to('.preloaderOverlay', { opacity: 1 }, '+=1.4');
      tl.fromTo('.preloaderContainer', { 'clipPath': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' }, { 'clipPath': 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)' }, '+=0.4');
    }, 1000);

  });

  return (
    <>
      {isLoading ? (
        /* Your preloader animation or content goes here */
        <div className='preloaderContainer w-screen h-screen overflow-hidden flex items-center justify-center'>
          <div className='preloaderOverlay fixed bottom-0 left-0 w-full h-full bg-red opacity-0'></div>
          <div className='bandLabPreloaderLogo fixed w-fit aspect-square flex h-fit flex-col justify-center m-auto top-[15px] bottom-0 right-[15px] left-0 py-0 px-[20vw] origin-center'>
            <AnimatedText text="BandLab" />
            <AnimatedText text="Technologies" />
          </div>
        </div>
      ) : (
        /* Render the actual homepage content */
        <>{children}</>
      )}
    </>
  );
};

export default Preloader;
